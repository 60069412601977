@mixin d {
  @media only screen and (max-width: "1120px") {
    @content; } }
@mixin t {
  @media only screen and (max-width: "1023px") {
    @content; } }
@mixin m {
  @media only screen and (max-width: "767px") {
    @content; } }
@mixin s {
  @media only screen and (max-width: "474px") {
    @content; } }

@mixin f {
  font-family: 'Karla', Helvetica, Arial, sans-serif; }
@mixin fa {
  font-family: 'Alegreya', Helvetica, Arial, serif;
  font-weight: 700; }

body {
  @include f;
  font-size: 14px;
  line-height: 1.3; }
button,
input {
  @include f; }
a {
  color: white;
  transition: opacity .2s;
  &:hover {
    opacity: .7; } }

.page {
  display: flex;
  min-height: 100vh;
  padding: 15px 15px 10px;
  background: url(../img/bg.jpg) no-repeat 50% 50% / cover;
  color: white;
  flex-direction: column;
  @include m {
    padding: 15px 10px 20px; }
  &__middle {
    margin: auto 0;
    padding: 20px 0 18px;
    @include m {
      margin-bottom: 68px;
      padding: 0; } } }

.socials {
  display: flex;
  margin-bottom: 3px;
  justify-content: flex-end;
  @include m {
    margin-bottom: 48px;
    padding-right: 5px; }
  &__link {
    margin-left: 8px; }
  &__pic {
    display: block; } }

.logo {
  margin: 0 auto;
  font-size: 0;
  text-align: center;
  @include m {
    margin-bottom: 48px; } }

.center {
  max-width: 770px;
  margin: 0 auto;
  text-align: center; }

.title {
  margin-bottom: 52px;
  @include fa;
  font-size: 60px;
  line-height: (7/6);
  @include m {
    margin-bottom: 34px;
    font-size: 43px; } }

.btn {
  display: inline-block;
  position: relative;
  min-width: 234px;
  border-radius: 2px;
  border: 2px solid white;
  font-size: 20px;
  font-weight: 700;
  color: black;
  letter-spacing: -0.3px;
  text-align: center;
  text-decoration: none;
  perspective: 100;
  z-index: 2;
  &__label {
    display: block;
    padding: 10px 20px;
    background: white;
    border-radius: 2px;
    transform: translate3d(0, -10px, 10px);
    transition: transform .2s, border-radius .2s;
    pointer-events: none;
    z-index: -2; }
  &:hover {
    opacity: 1; }
  &:hover &__label {
    border-radius: 0;
    transform: translate3d(0, 0, 0); } }

.progress {
  max-width: 588px;
  margin: 0 auto 10px;
  @include m {
    margin-bottom: 50px; }
  &__text {
    margin-bottom: 16px;
    font-size: 20px; }
  &__container {
    height: 15px;
    margin-bottom: 8px;
    box-shadow: inset 0 0 0 1px white;
    border-radius: 7px; }
  &__position {
    height: 100%;
    background: white;
    border-radius: 7px; }
  &__status {
    font-size: 18px; } }

.about {
  text-align: right;
  @include m {
    text-align: center; }
  a {
    font-weight: 700; } }








