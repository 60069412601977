html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

img, fieldset, a img {
  border: none;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none;
}

input[type="submit"],
button {
  cursor: pointer;
}

input[type="submit"]::-moz-focus-inner,
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

input, button {
  margin: 0;
  padding: 0;
  border: 0;
}

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none;
}

ul, ol {
  list-style-type: none;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

.icon-facebook {
  width: 0.53em;
  height: 1em;
  fill: #000;
}

body {
  font-family: 'Karla', Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.3;
}

button,
input {
  font-family: 'Karla', Helvetica, Arial, sans-serif;
}

a {
  color: white;
  transition: opacity .2s;
}

a:hover {
  opacity: .7;
}

.page {
  display: flex;
  min-height: 100vh;
  padding: 15px 15px 10px;
  background: url(../img/bg.jpg) no-repeat 50% 50%/cover;
  color: white;
  flex-direction: column;
}

@media only screen and (max-width: 767px) {
  .page {
    padding: 15px 10px 20px;
  }
}

.page__middle {
  margin: auto 0;
  padding: 20px 0 18px;
}

@media only screen and (max-width: 767px) {
  .page__middle {
    margin-bottom: 68px;
    padding: 0;
  }
}

.socials {
  display: flex;
  margin-bottom: 3px;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .socials {
    margin-bottom: 48px;
    padding-right: 5px;
  }
}

.socials__link {
  margin-left: 8px;
}

.socials__pic {
  display: block;
}

.logo {
  margin: 0 auto;
  font-size: 0;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .logo {
    margin-bottom: 48px;
  }
}

.center {
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
}

.title {
  margin-bottom: 52px;
  font-family: 'Alegreya', Helvetica, Arial, serif;
  font-weight: 700;
  font-size: 60px;
  line-height: 1.16667;
}

@media only screen and (max-width: 767px) {
  .title {
    margin-bottom: 34px;
    font-size: 43px;
  }
}

.btn {
  display: inline-block;
  position: relative;
  min-width: 234px;
  border-radius: 2px;
  border: 2px solid white;
  font-size: 20px;
  font-weight: 700;
  color: black;
  letter-spacing: -0.3px;
  text-align: center;
  text-decoration: none;
  perspective: 100;
  z-index: 2;
}

.btn__label {
  display: block;
  padding: 10px 20px;
  background: white;
  border-radius: 2px;
  transform: translate3d(0, -10px, 10px);
  transition: transform .2s, border-radius .2s;
  pointer-events: none;
  z-index: -2;
}

.btn:hover {
  opacity: 1;
}

.btn:hover .btn__label {
  border-radius: 0;
  transform: translate3d(0, 0, 0);
}

.progress {
  max-width: 588px;
  margin: 0 auto 10px;
}

@media only screen and (max-width: 767px) {
  .progress {
    margin-bottom: 50px;
  }
}

.progress__text {
  margin-bottom: 16px;
  font-size: 20px;
}

.progress__container {
  height: 15px;
  margin-bottom: 8px;
  box-shadow: inset 0 0 0 1px white;
  border-radius: 7px;
}

.progress__position {
  height: 100%;
  background: white;
  border-radius: 7px;
}

.progress__status {
  font-size: 18px;
}

.about {
  text-align: right;
}

@media only screen and (max-width: 767px) {
  .about {
    text-align: center;
  }
}

.about a {
  font-weight: 700;
}
